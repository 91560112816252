<template>
  <main class="site-main">

    <!--상단 타이틀 영역 s-->
    <div class="top-level-page-area top-level-page-area-04">
      <div class="bms-relaunch-comp-v2">
        <h1 class="page-header2">사이트맵</h1>
      </div>
    </div>

    <div class="container">

      <!--Breadcrumb 영역 s-->
      <div class="top-nav-container">
        <nav role="navigator">
          <ul class="breadcrumbs list-unstyled hide-sm">
            <li class="breadcrumb">사이트맵</li>
          </ul>
        </nav>
      </div>
      <!--Breadcrumb 영역 e-->

      <div class="section">

        <div class="sitemap_area">
          <div class="menu_sec">
            <div class="depth1_area">
              <router-link :to="'/'">홈<span>></span></router-link>
            </div>
          </div>

          <div class="menu_sec">
            <div class="depth1_area">
              <router-link :to="'/menu/sprycel'">제품<span>></span></router-link>
            </div>
            <div class="depth2_area">
              <ul>
                <li>
                  Hematology
                  <ul>
                    <li>
                      <router-link :to="'/menu/inrebic'">INREBIC<sup>®</sup>(fedratinib)</router-link>
                    </li>
                    <li>
                      <router-link :to="'/menu/onureg'">ONUREG<sup>®</sup>(azacitidine)</router-link>
                    </li>
                    <li>
                      <router-link :to="'/menu/reblozyl'">REBLOZYL<sup>®</sup>(luspatercept-aamt)</router-link>
                    </li>
                    <li>
                      <router-link :to="'/menu/revlimid-pomalyst'">REVLIMID<sup>®</sup>(lenalidomide)/POMALYST<sup>®</sup>(pomalidomide)</router-link>
                    </li>
                    <li>
                      <router-link :to="'/menu/sprycel'">SPRYCEL<sup>®</sup>(dasatinib)</router-link>
                    </li>
                    <li>
                      <a href="https://www.celgene.kr/media2.celgene.com/content/uploads/sites/22/Thalidomide_Korea_PIL.pdf" target="_blank">THALIDOMIDE CELGENE<sup>®</sup>(thalidomide)</a>
                    </li>
                    <li>
                      <router-link :to="'/menu/vidaza'">VIDAZA<sup>®</sup>(azacitidine)</router-link>
                    </li>
                  </ul>
                </li>
                <li>
                  Oncology
                  <ul>
                    <li>
                      <router-link :to="'/menu/opdivo-yervoy'">OPDIVO<sup>®</sup>(nivolumab) / YERVOY<sup>®</sup>(ipilimumab)</router-link>
                    </li>
                    <li>
                      <router-link :to="'/menu/abraxane'">ABRAXANE<sup>®</sup>(paclitaxel protein-bound particles for injectable suspension) (albumin-bound)</router-link>
                    </li>
                  </ul>
                </li>
                <li>
                  Immunology
                  <ul>
                    <li>
                      <router-link :to="'/menu/zeposia'">ZEPOSIA<sup>®</sup>(ozanimod)</router-link>
                    </li>
                    <li>
                      <router-link :to="'/menu/orencia'">ORENCIA<sup>®</sup>(abatacept)</router-link>
                    </li>
                    <li>
                      <router-link :to="'/menu/sotyktu'">SOTYKTU<sup>™</sup>(sotyktu)</router-link>
                    </li>
                  </ul>
                </li>
                <li>
                  Virology
                  <ul>
                    <li>
                      <router-link :to="'/menu/baraclude'">BARACLUDE<sup>®</sup>(entecavir)</router-link>
                    </li>
                    <li>
                      <a href="https://www.bms.com/assets/bms/korea/documents/pi_pdfs/reyataz-pi.pdf" target="_blank">REYATAZ<sup>®</sup>(atazanavir)</a>
                    </li>
                    <li>
                      <a href="https://www.bms.com/assets/bms/korea/documents/pi_pdfs/evotaz-pi.pdf" target="_blank">EVOTAZ<sup>®</sup>(atazanavir and cobicistat)</a>
                    </li>
                  </ul>
                </li>
                <li>
                  Cardiology
                  <ul>
                    <li>
                      <router-link :to="'/menu/eliquis'">ELIQUIS<sup>®</sup>(apixaban)</router-link>
                    </li>
                    <li>
                      <router-link :to="'/menu/camzyos'">CAMZYOS<sup>™</sup>(mavacamten)</router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>

          <div class="menu_sec">
            <div class="depth1_area">
              <router-link :to="'/webinar-symposium/webinar-live'">웨비나 & VOD</router-link>
              <span>></span></div>
            <div class="depth2_area">
              <ul>
                <li>
                  <router-link :to="'/webinar-symposium/webinar-live'">웨비나 라이브</router-link>
                </li>
                <li>
                  <router-link :to="'/webinar-symposium/webinar-vod'">웨비나 다시보기</router-link>
                </li>
                <li>
                  <router-link :to="'/webinar-symposium/symposium-vod'">VOD Contents</router-link>
                </li>
              </ul>
            </div>
          </div>

          <div class="menu_sec">
            <div class="depth1_area">
              <router-link :to="'/request-service'">BMS 직원 방문 및 연락 요청<span>></span></router-link>
            </div>
          </div>

          <div class="menu_sec">
            <div class="depth1_area">BMS 소식<span>></span></div>
            <div class="depth2_area">
              <ul>
                <li>
                  <router-link :to="'news-and-notice'">뉴스 & 공지사항</router-link>
                </li>
                <li>
                  <router-link :to="'/news/social-contribute'">사회공헌활동</router-link>
                </li>
              </ul>
            </div>
          </div>

          <div class="menu_sec">
            <div class="depth1_area">의학정보요청 및 이상사례보고<span>></span></div>
            <div class="depth2_area">
              <ul>
                <li>
                  <router-link :to="'/request-report'">의학정보요청</router-link>
                </li>
                <li>
                  <router-link :to="'/request-report'">이상사례보고</router-link>
                </li>
              </ul>
            </div>
          </div>

          <div class="menu_sec">
            <div class="depth1_area">파이프라인<span>></span></div>
            <div class="depth2_area">
              <ul>
                <li><a href="https://www.bms.com/researchers-and-partners/in-the-pipeline.html"
                       target="_blank">Pipeline at a Glance</a></li>
                <li><a
                    href="https://www.bms.com/healthcare-providers/early-patient-access-to-investigational-medicine/investigational-drugs-available.html"
                    target="_blank">Pre-Approval Access</a></li>
              </ul>
            </div>
          </div>

          <div class="menu_sec">
            <div class="depth1_area">My Page<span>></span></div>
            <div class="depth2_area">
              <ul>
                <li>
                  <router-link :to="'/my-page/pre-registration'">웨비나 사전등록</router-link>
                </li>
                <li>
                  <router-link :to="'/my-page/interest-content'">관심 콘텐츠</router-link>
                </li>
                <li>
                  <router-link :to="'/my-page/history-acting'">나의 활동 이력</router-link>
                </li>
                <li>
                  <router-link :to="'/my-page/interest-therapeutic-area'">관심 질환 영역</router-link>
                </li>
                <li>
                  <router-link :to="'/my-page/modify-information'">내 정보 수정</router-link>
                </li>
              </ul>
            </div>
          </div>


        </div>

      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "Sitemap",
  data() {
    return {
      productMenu: [
        {
          name: 'Hematology',
          href: '/menu/sprycel',
          child: [
            {
              name: 'SPRYCEL<sup>®</sup>(dasatinib)',
              href: '/menu/sprycel'
            },
            {
              name: 'VIDAZA<sup>®</sup>(azacitidine)',
              href: '/menu/vidaza'
            },
            {
              name: 'REVLIMID<sup>®</sup>(lenalidomide)/POMALYST<sup>®</sup>(pomalidomide)',
              href: '/menu/revlimid-pomalyst'
            }
          ]
        },
        {
          name: 'Oncology',
          href: '/menu/opdivo-yervoy',
          child: [
            {
              name: 'OPDIVO<sup>®</sup> (nivolumab) / YERVOY<sup>®</sup> (ipilimumab)',
              href: '/menu/opdivo-yervoy'
            },
            {
              name: 'ABRAXANE<sup>®</sup> <span>(paclitaxel protein-bound particles for injectable suspension) (albumin-bound)</span>',
              href: '/menu/abraxane',
            }
          ]
        },
        {
          name: 'Immunology',
          href: '/menu/orencia',
          child: [
            {
              name: 'ORENCIA<sup>®</sup>(abatacept)',
              href: '/menu/orencia',
            }

          ]
        },
        {
          name: 'Virology',
          href: '/menu/baraclude',
          child: [
            {
              name: 'BARACLUDE<sup>®</sup>(entecavir)',
              href: '/menu/baraclude',
            }
          ]
        },
        {
          name: 'Cardiology',
          href: '/menu/eliquis',
          child: [
            {
              name: 'ELIQUIS<sup>®</sup>(apixaban)',
              href: '/menu/eliquis',
            }
          ]
        },
      ]
    }
  },
}
</script>

<style scoped>

</style>
