import { render, staticRenderFns } from "./Sitemap.vue?vue&type=template&id=42f8d9fb&scoped=true&"
import script from "./Sitemap.vue?vue&type=script&lang=js&"
export * from "./Sitemap.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42f8d9fb",
  null
  
)

export default component.exports